exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-brands-js": () => import("./../../../src/pages/brands.js" /* webpackChunkName: "component---src-pages-brands-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-confirm-js": () => import("./../../../src/pages/confirm.js" /* webpackChunkName: "component---src-pages-confirm-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-templates-wp-front-page-js": () => import("./../../../src/templates/WpFrontPage.js" /* webpackChunkName: "component---src-templates-wp-front-page-js" */),
  "component---src-templates-wp-product-archive-js": () => import("./../../../src/templates/WpProductArchive.js" /* webpackChunkName: "component---src-templates-wp-product-archive-js" */),
  "component---src-templates-wp-product-js": () => import("./../../../src/templates/WpProduct.js" /* webpackChunkName: "component---src-templates-wp-product-js" */)
}

