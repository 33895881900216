import React from "react"
import { Provider } from "react-redux"
import cartReducer from "./src/components/reducer/cartReducer"

import { createStore } from "redux"
import { persistStore, persistReducer } from "redux-persist"
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, cartReducer)

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  let store = createStore(persistedReducer)
  let persistor = persistStore(store)
  return <Provider store={store}>{element}</Provider>
}