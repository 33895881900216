import { ADD_TO_CART, REMOVE_ITEM } from "../actions/action-types/cart-actions"
import { CLEAR_CART } from "../actions/action-types/cart-actions"

const initState = {
    addedItems: [],
    total: 0
}
const cartReducer = (state = initState, action) => {

    if (action.type === ADD_TO_CART) {
        let addedItem = action
        let existed_item = state.addedItems.find(item => action.id === item.id)
        if (existed_item) {
            existed_item.quantity += 1
            return {
                ...state,
                total: state.total + addedItem.price
            }
        }
        else {
            addedItem.quantity = 1
            let newTotal = state.total + addedItem.price

            return {
                ...state,
                addedItems: [...state.addedItems, addedItem],
                total: newTotal
            }
        }
    }
    if(action.type === REMOVE_ITEM){
        let itemToRemove= state.addedItems.find(item=> action.id === item.id)
        let new_items = state.addedItems.filter(item=> action.id !== item.id)
        
        let newTotal = state.total - (itemToRemove.price * itemToRemove.quantity )
        console.log(itemToRemove)
        return{
            ...state,
            addedItems: new_items,
            total: newTotal
        }
    }
    
    if (action.type === CLEAR_CART) {
        state = initState
        return state
    }
    else {
        return state
    }
}
export default cartReducer;